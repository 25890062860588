export const useBrowserInfoDumper = () => {

    const getUserInfo = () => {
        // Отримання інформації про браузер та пристрій
        const userAgent = navigator.userAgent;
        let deviceType = "Unknown";
        if (/Mobi|Android|iPhone|iPad|iPod/.test(userAgent)) {
            deviceType = "Mobile";
        } else if (/Tablet|iPad/.test(userAgent)) {
            deviceType = "Tablet";
        } else {
            deviceType = "Desktop";
        }

        // Отримання назви браузера
        let browserName = "Unknown";
        if (userAgent.indexOf("Firefox") > -1) {
            browserName = "Firefox";
        } else if (userAgent.indexOf("SamsungBrowser") > -1) {
            browserName = "Samsung Internet";
        } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
            browserName = "Opera";
        } else if (userAgent.indexOf("Trident") > -1) {
            browserName = "Internet Explorer";
        } else if (userAgent.indexOf("Edge") > -1) {
            browserName = "Microsoft Edge";
        } else if (userAgent.indexOf("Chrome") > -1) {
            browserName = "Chrome";
        } else if (userAgent.indexOf("Safari") > -1) {
            browserName = "Safari";
        }

        // Отримання ширини та висоти екрану
        const screenWidth = screen.width;
        const screenHeight = screen.height;

        return {
            deviceType: deviceType,
            browserName: browserName,
            screenWidth: screenWidth,
            screenHeight: screenHeight
        }
    }

    const dump = () => {
        useApi().putBrowserInfo({request: getUserInfo()}).then(r => {})
    }

    return {
        dump,
    }
}