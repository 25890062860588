<script setup lang="ts">

import {storeToRefs} from "pinia";
import {useInterfaceStore} from "~/stores/interface";
import {useSessionsStore} from "~/stores/sessions";
import Roles from "~/utils/roles";

const {t} = useI18n()

const {isAuthorized} = usePermissions()
const sessionsStore = useSessionsStore()

if (!sessionsStore.sessions.length) {
  await sessionsStore.load()
}

const usersAffiliationTitle = computed(() => {
  if ([Roles.ISTAR_DIVISION.value, Roles.TEAM.value, Roles.VIEWER.value].includes(user.value.role)) {
    return user.value.divisionName
  }
  if (user.value.role === Roles.ISTAR_ZONE.value) {
    return user.value.zoneName
  }
  if (user.value.role === Roles.OTG_COORDINATOR.value) {
    return user.value.otgName
  }

  return null
})

const routesData = computed(() => {

  const {user} = useAuth()

  const pilotAssignedToTeam = user.value.role == Roles.TEAM.value && sessionsStore.sessions.length > 0

  return [
    {
      title: 'Матриця польотів',
      path: '/fly',
      icon: 'mdi-airplane',
      available: isAuthorized('fly_page:view'),
    },
    {
      title: 'Історія',
      path: '/history',
      available: isAuthorized('flight_history:view') && (user.value.role !== Roles.TEAM.value || pilotAssignedToTeam),
      icon: 'mdi-history',
    },
    {
      title: 'Керування ' + (user.value.role === Roles.OTG_COORDINATOR.value
          ? 'підрозділами' : 'підрозділом'), // division-admin
      icon: 'mdi-check-decagram-outline',
      children: [
        {
          title: 'Бойові чергування',
          path: "/division-admin/sessions",
          available: isAuthorized("combat_sessions_page:view"),
          icon: 'mdi-timer-star-outline',
        },
        {
          title: 'Зони',
          path: "/division-admin/zone-requests",
          available: isAuthorized("zone-requests:management"),
          icon: 'mdi-map-marker-radius',
        },
        {
          title: 'Цілі',
          path: "/division-admin/targets",
          available: isAuthorized("targets:management"),
          icon: 'mdi-target-account',
        },
      ]
    },
    {
      title: 'Управління зоною',
      icon: 'mdi-hammer-wrench',
      children: [
        {
          title: 'Підрозділи в зоні ',
          path: "/zone-admin/active",
          available: isAuthorized("zone_admin:view_divisions"),
          icon: 'mdi-sitemap-outline',
        },
        {
          title: 'Заявки',
          path: "/zone-admin/inactive",
          available: isAuthorized("zone_admin:view_divisions"),
          icon: 'mdi-map-clock-outline',
        },
      ]
    },
    {
      title: 'Адмінка',
      icon: 'mdi-security',
      children: [
        {
          path: '/admin/divisions',
          title: 'Підрозділи',
          available: isAuthorized('admin:divisions_management'),
          icon: 'mdi-sitemap',
        },
        {
          path: '/admin/teams',
          title: 'Екіпажі',
          available: isAuthorized('admin:teams_management'),
          icon: 'mdi-account-group',
        },
        {
          path: '/admin/users',
          title: 'Користувачі',
          available: isAuthorized('admin:users_management'),
          'icon': 'mdi-account',
        },
        {
          path: '/admin/zones',
          title: 'Зони',
          available: isAuthorized('admin:zones_management'),
          icon: 'mdi-map-marker-radius',
        },
        {
          path: '/admin/otg',
          title: 'ОТУВ',
          available: isAuthorized('admin:otg_management'),
          icon: 'mdi-map-marker-distance',
        },
        {
          path: '/admin/osg',
          title: 'ОСУВ',
          available: isAuthorized('admin:osg_management'),
          icon: 'mdi-map',
        },
        {
          path: '/admin/ammo',
          title: 'БК',
          available: isAuthorized('admin:ammo_management'),
          icon: 'mdi-ammunition',
        },
        {
          path: '/admin/faqs',
          title: 'Редактор FAQ',
          available: isAuthorized('admin:faq_management'),
          icon: 'mdi-help-box-multiple',
        },
        {
          path: '/admin/activity',
          title: 'Аналітика',
          available: isAuthorized('admin:analytics:view'),
          icon: 'mdi-poll',
        },
      ],
    },
    {
      title: 'Допомога',
      path: '/help',
      available: isAuthorized('help_page:view') && (user.value.role !== Roles.TEAM.value || pilotAssignedToTeam),
      icon: 'mdi-help-circle-outline',
    },
  ];
})

const {user, signOut} = useAuth()
const loadingSignOut = ref(false)
const menu = ref(false)
const admins: Ref<any> = ref([])

admins.value.push(['Management', 'mdi-account-multiple-outline'])
admins.value.push(['Settings', 'mdi-cog-outline'])

const {darkTheme, openedDrawer} = storeToRefs(useInterfaceStore())

const googleSignOut = async () => {
  loadingSignOut.value = true
  await signOut()
  loadingSignOut.value = false
}
</script>

<template>
  <v-app-bar color="app-bar">
    <template v-slot:prepend>
      <v-app-bar-nav-icon @click.stop="openedDrawer = !openedDrawer"/>

      <v-avatar size="50" rounded="0">
        <v-img src="/new-logo.png" :cover="false"
        />
      </v-avatar>
    </template>

    <v-app-bar-title>
      <div class="levitation-bar-title">
        Levitation
      </div>
      <div class="levitation-bar-subtitle">
        Контроль місій FPV
      </div>
    </v-app-bar-title>

    <template v-slot:append>
      <v-btn :icon="true" v-if="user.impersonatedBy">
        <v-icon
            color="error"
            icon="mdi-account-key"
        />
        <v-tooltip
            activator="parent"
            location="start"
            open-on-click
        >
          Авторизовано як:<br/>
          Користувач: <b>{{ user.name }}</b><br/>
          Роль: <b>{{ t('titles.role.' + user.role) }}</b>
        </v-tooltip>
      </v-btn>

      <v-menu v-model="menu" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" icon>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card min-width="250">
          <v-card-title>
            {{ user.name }}
          </v-card-title>
          <v-card-text>
            <div class="text-shaded">
              {{ t('titles.role.' + user.role) }}
            </div>
            <div class="text-shaded" v-if="usersAffiliationTitle">
              {{ usersAffiliationTitle }}
            </div>

          </v-card-text>

          <v-divider></v-divider>

          <v-list>
            <v-list-item>
              <v-switch
                  v-model="darkTheme"
                  label="Темна тема"
                  :hide-details="true"
              ></v-switch>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
                block
                text="Вийти"
                variant="tonal"
                color="red-darken-1"
                append-icon="mdi-exit-run"
                @click="googleSignOut"
                :loading="loadingSignOut"
            />
          </v-card-actions>
        </v-card>
      </v-menu>

    </template>
  </v-app-bar>

  <v-navigation-drawer width="300" v-model="openedDrawer" mobile-breakpoint="md">
    <v-list>
      <template v-for="route in routesData">
        <template v-if="!route.children">
          <v-list-item
              :prepend-icon="route.icon"
              :title="route.title"
              :to="route.path"
              v-if="route.available"
          />
        </template>
        <template v-else>
          <v-list-group
              :prepend-icon="route.icon"
              :value="route.title"
              v-if="route.children.some(item => item.available)"
          >
            <template v-slot:activator="{ props }">
              <v-list-item
                  v-bind="props"
                  :title="route.title"
              ></v-list-item>
            </template>

            <template v-for="childRoute in route.children">
              <v-list-item
                  :key="childRoute.path"
                  :title="childRoute.title"
                  :to="childRoute.path"
                  v-if="childRoute.available"
                  :prepend-icon="childRoute.icon"
              />
            </template>
          </v-list-group>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<style>
.v-toolbar-title.v-app-bar-title {
  margin-inline-start: 12px !important;
}

.levitation-bar-title {
  font-size: 23pt;
  line-height: 23pt;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  font-family: "Alumni Sans", sans-serif;
  font-weight: 400;
}

.levitation-bar-subtitle {
  font-size: 15pt;
  line-height: 15pt;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  opacity: 50%;
  font-family: "Alumni Sans", sans-serif;
  font-weight: 400;
}

.v-navigation-drawer__content .v-list-item__spacer {
  width: 16px!important;
}

.v-navigation-drawer__content .v-list-group {
  --prepend-width: 24px;
}
</style>